import {
    reqProjectInfoCount,
    reqProjectInfoList
} from "../../request/api";
import {
    errorAlert
} from '@/utils/alert'

// 状态
const state = {
    allList: [], // 存放所有信息
    search: [],
    list: [],
    page: 1,
    total: 0,
    size: 10,
    name: "",
    loading: false,
}
// 修改状态
const mutations = {
    getAllList(state, arr) {
        state.allList = arr
    },
    changeList(state, arr) {
        state.list = arr
        state.loading = false
    },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
        state.loading = true
    },
    changeSearch(state, key) {
        state.search = [];
        state.page = 1;
        state.name = key
        state.loading = true
    },
}
// 异步和逻辑操作
const actions = {
    reqAll({
        commit
    }) {
        // 获取表格数据
        let orgIdList = JSON.parse(sessionStorage.getItem("user")).org;
        const orgId = []
        orgIdList.forEach(item => {
            orgId.push(item.org_id);
        });
        reqProjectInfoList({
            orgId: orgId,
            construction_certificate_no: '',
            page: "",
            limit: "",
        }).then(res => {
            const Alllist = res.data ? res.data : [];
            commit('getAllList', Alllist)
        }).catch(err => {
            console.log(err);
            errorAlert("服务器请求错误");
        });
    },
    reqList({
        commit,
        dispatch,
        state: {
            page,
            size,
            search,
            name
        }
    }) {
        // 获取表格数据
        let orgIdList = JSON.parse(sessionStorage.getItem("user")).org;
        const orgId = []
        orgIdList.forEach(item => {
            orgId.push(item.org_id);
        });
        // if (search.length == 0) {
        reqProjectInfoList({
            orgId: orgId,
            construction_certificate_no: "",
            page,
            limit: size,
            name: name
        }).then(res => {
            var list = res.data.data ? res.data.data : []
            if (list.length === 0 && page !== 1) {
                commit('changePage', page - 1)
                dispatch('reqList')
                return
            }
            commit('changeList', list)
            commit('changeTotal', res.data.total)
        }).catch(() => {
            errorAlert("服务器请求错误");
        });
        // } else {
        //     page--;
        //     let a = search.slice(page * 10, (page * 10) + 10);
        //     console.log(a);
        //     commit('changeList', a)
        // }
    },
    // 请求总数
    reqTotal({
        commit
    }) {
        reqProjectInfoCount().then(res => {
            commit('changeTotal', Number(res.data.data.total))
        }).catch(err => {
            console.log(err);
            errorAlert("服务器请求错误");
        });
    },
    changePage({
        commit,
        dispatch
    }, num) {
        commit('changePage', num)
        dispatch('reqList')
    },
    // 搜索框
    changeSearch({
        commit,
        dispatch
    }, key) {
        commit('changeSearch', key)
        dispatch('reqList')
    }
}
// getters 导出数据
const getters = {
    list(state) {
        return state.list
    },
    loading(state) {
        return state.loading
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.size
    },
    page(state) {
        return state.page
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true // 命名空间
}