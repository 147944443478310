import {
    reqDevPageList,
    reqJudgeList
} from "../../request/api";
import {
    errorAlert
} from '@/utils/alert'

// 状态
const state = {
    allList: [], // 存放所有信息
    search: [],
    key: "",
    list: [],
    page: 1,
    total: 0,
    limit: 10,
    deviceName: "",
    status: "",
    access: "",
    loading: false,
}
// 修改状态
const mutations = {
    getAllList(state, arr) {
        state.allList = arr
    },
    changeList(state, arr) {
        state.list = arr
        state.loading = false
    },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
        state.loading = true
    },
    changeName(state, key) {
        state.deviceName = key
        state.search = [];
        state.loading = true
    },
    changeStatus(state, key) {
        state.status = key
        state.search = [];
        state.loading = true
    },
    changeAccess(state, key) {
        state.access = key
        state.search = [];
        state.loading = true
    },
    changeSearch(state, key) {
        state.key = key
        state.search = [];
        state.loading = true
    },
}
// 异步和逻辑操作
const actions = {
    reqJudge({
        dispatch
    }) {
        let org_id = JSON.parse(sessionStorage.getItem('user')).org[0].org_id
        reqJudgeList(org_id).then(res => {
            console.log(res.data.data.status, 'status');
            if (res.data.data.status == 0) {
                dispatch('reqList')
            }
        })
    },
    reqList({
        commit,
        dispatch,
        state: {
            page,
            limit,
            search,
            key,
            deviceName,
            status,
            access
        }
    }) {
        console.log();
        if (search.length == 0) {
            reqDevPageList({
                deviceName: deviceName ? deviceName : "",
                org_id: key ? key : "",
                page,
                limit,
                status: status ? status : "",
                access: access ? access : "",
            }).then(res => {
                var list = res.data.data.records ? res.data.data.records : []
                var n = res.data.data ? res.data.data.total : []
                if (list.length === 0 && page !== 1) {
                    commit('changePage', page - 1)
                    dispatch('reqList')
                    return
                }
                commit('changeList', list)
                commit('changeTotal', n)
            }).catch(err => {
                console.log(err);
                errorAlert("服务器请求错误");
            });
        } else {
            page--;
            let a = search.slice(page * 10, (page * 10) + 10);
            commit('changeList', a)
        }
    },
    changePage({
        commit,
        dispatch
    }, num) {
        commit('changePage', num)
        dispatch('reqList')
    },
    // 搜索框
    changeSearch({
        commit,
        dispatch
    }, key) {
        if (key) {
            for (var k in key) {
                // console.log(k, key[k], '拿到的值');
                if (k == "项目") {
                    commit('changeSearch', key[k])
                } else if (k == '设备') {
                    commit('changeName', key[k])
                } else if (k == "状态") {
                    commit('changeStatus', key[k])
                } else if (k == "进出") {
                    commit('changeAccess', key[k])
                }
            }
        } else {
            commit('changeSearch', '')
        }
        commit('changePage', 1)
        dispatch('reqList')
    }
}
// getters 导出数据
const getters = {
    list(state) {
        return state.list
    },
    loading(state) {
        return state.loading
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.limit
    },
    page(state) {
        return state.page
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true // 命名空间
}