import {
    reqPostCount,
    reqPostlist
} from "../../request/api";
import {
    errorAlert
} from '@/utils/alert'
// 状态
const state = {
    list: [],
    page: 1,
    limit: 10,
    total: 0,
    loading: false,
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
        state.loading = false
    },
    changeTotal(state, num) {
        state.total = num
    },
    changePage(state, num) {
        state.page = num
        state.loading = true
    },
}
// 异步和逻辑操作
const actions = {
    reqList({
        commit,
        dispatch,
        state: {
            page,
            limit
        }
    }) {
        reqPostlist({
            po_name: '',
            page,
            limit
        }).then(res => {
            const list = res.data ? res.data : []
            if (list.length === 0 && page !== 1) {
                commit('changePage', page - 1)
                dispatch('reqList')
                return
            }
            commit('changeList', list)
        }).catch(err => {
            console.log(err);
            errorAlert("服务器请求错误");
        });
    },
    // 请求总数
    reqTotal({
        commit
    }) {
        reqPostCount().then(res => {
            commit('changeTotal', Number(res.data.data.total))
        }).catch(err => {
            console.log(err);
            errorAlert("服务器请求错误");
        });
    },
    changePage({
        commit,
        dispatch
    }, num) {
        // 提交页码值 使page等于num(当前页码值)
        commit('changePage', num)
        // 发送请求
        dispatch('reqList')
    }
}
// getters 导出数据
const getters = {
    list(state) {
        return state.list
    },
    loading(state) {
        return state.loading
    },
    total(state) {
        return state.total
    },
    limit(state) {
        return state.limit
    },
    page(state) {
        return state.page
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true // 命名空间
}