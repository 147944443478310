import Vue from 'vue'
// 重置样式 reset.css
import './assets/css/reset.css'
import './less/public.less';
//引入联动组件
import VueAreaLinkage from 'vue-area-linkage'
Vue.use(VueAreaLinkage)
import App from './App.vue'
import router from './router'
import store from './store'
// import 'default-passive-events'
import {
  preventReClick
} from '@/directive/preventReClick'

//element全局
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入公共组件
import './components'
//百度地图
import BaiduMap from 'vue-baidu-map'

//拖拽
import './utils/dialog-directive'

// import VideoPlayer from "vue-video-player";
// import 'video.js/dist/video-js.css';
// import 'vue-video-player/src/custom-theme.css';
// import 'video.js/dist/lang/zh-CN.js'
// Vue.use(VideoPlayer)

Vue.prototype.$bus = new Vue()
Vue.prototype.$preventReClick = preventReClick;
Vue.config.productionTip = false
// Vue.prototype.$buss = new Vue()
Vue.use(ElementUI);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 */
  ak: 'XALQoWqGADldBr9X0ResVnGZL4HYn1r3'
})
new Vue({
  router,
  store,
  render: h => h(App),
  beforeCreate () {
    Vue.prototype.$bus = this
    // Vue.prototype.$buss=this
  }
}).$mount('#app')
