import {
    reqRoleAllList
} from '../../request/api'
// 状态
const state = {
    list: []
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
    }
}
// 异步和逻辑操作
const actions = {
    reqList(context) {
        reqRoleAllList().then(res => {
            const list = res.data ? res.data : []
            context.commit('changeList', list)
        })
    }
}
// 导出数据
const getters = {
    list(state) {
        return state.list
    }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}