import {
    reqOrgOutList,
    // reqspecscount
} from '../../request/api'
// 状态
const state = {
    list: [],
}
// 修改状态
const mutations = {
    changeList(state, arr) {
        state.list = arr
    },
}
// 异步和逻辑操作
const actions = {
    reqList({ commit, state: {page, limit} }) {
        reqOrgOutList().then(res => {
            const list = res.data.data.items ? res.data.data.items : []
            if(list.length === 0 && page !== 1) {
                commit('changePage', page-1)
                dispatch('reqList')
                return
            }
            commit('changeList', list)
        }).catch(error => {
            errorAlert("请检查网络")
        })
    },
    reqTotal({
        commit
    }) {
        reqspecscount().then(res => {
            commit('changeTotal', res.data.list[0].total)
        })
    },
    changePage({commit,dispatch}, num) {
        // num当前页码值 page=num
        commit('changePage', num)
        // 发请求
        dispatch('reqList')
    }
}
// 导出数据
const getters = {
    list(state) {
        return state.list
    },
    // total(state) {
    //     return state.total
    // },
    // limit(state) {
    //     return state.limit
    // },
    // page(state) {
    //     return state.page
    // }
}

export default {
    state,
    mutations,
    actions,
    getters,
    namespaced: true
}