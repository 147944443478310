import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

import {
  state,
  mutations,
  getters
} from './mutations'
import actions from './actions'
import service_per from './modules/service_per'
import service_comp from './modules/service_comp'
import tofList from "./modules/tofList";
import roleList from './modules/roleList'
import person_msg from './modules/person_msg'
import project_msg from './modules/project_msg'
import role_msg from './modules/role_msg'
import service_tree from './modules/service_tree'
import service_compTree from './modules/service_compTree'
import service_compAllTree from './modules/service_compAllTree'
import project_equ from './modules/project_equ'
import attendance from './modules/attendance'
import projectAllper from './modules/projectAllper'

export default new Vuex.Store({
  state,
  getters,
  mutations,
  actions,
  modules: {
    service_per,
    service_comp,
    person_msg,
    project_msg,
    role_msg,
    roleList,
    tofList,
    service_tree,
    service_compTree,
    service_compAllTree,
    project_equ,
    attendance,
    projectAllper
  }
})