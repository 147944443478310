import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
Vue.use(VueRouter)
//// // 登录拦截
//筛选路由权限
function check (path, next) {
  // 当前用户有地址访问权限可以访问 放行
  if (store.state.user.jur_url.includes(path)) {
    next()
  } else {
    next('/login')
  }
}

export const IndexRoutes = [{
  path: 'project_msg',
  component: () => import('@/views/project_msg/project_msg.vue'),
  meta: {
    fa: '项目管理',
    title: '项目信息管理'
  },
  beforeEach (to, from, next) {
    check('/project_msg', next)
  }
},
{
  path: 'stats',
  component: () => import('@/views/stats/stats.vue'),
  beforeEach (to, from, next) {
    check('/stats', next)
  }
},
{
  path: 'project_perlist',
  component: () => import('@/views/project_perlist/project_perlist.vue'),
  meta: {
    fa: '项目管理',
    title: '项目人员列表'
  },
  beforeEach (to, from, next) {
    check('/project_perlist', next)
  }
},
{
  path: 'project_allper',
  component: () => import('@/views/project_allper/project_allper.vue'),
  meta: {
    fa: '项目管理',
    title: '项目人员'
  },
  beforeEach (to, from, next) {
    check('/project_allper', next)
  }
},
{
  path: 'role_msg',
  component: () => import('@/views/role_msg/role_msg.vue'),
  meta: {
    fa: '集团管理',
    title: '内部人员角色管理'
  },
  beforeEach (to, from, next) {
    check('/role_msg', next)
  }
},
{
  path: 'role_group',
  component: () => import('@/views/role_group/role_group.vue'),
  meta: {
    fa: '集团管理',
    title: '组织角色详情列表'
  },
  beforeEach (to, from, next) {
    check('/role_group', next)
  }
},
{
  path: 'project_equ',
  component: () => import('@/views/project_equ/project_equ.vue'),
  meta: {
    fa: '集团管理',
    title: '设备列表'
  },
  beforeEach (to, from, next) {
    check('/project_equ', next)
  },
},
{
  path: 'role_permission',
  component: () => import('@/views/role_permission/role_permission.vue'),
  meta: {
    fa: '集团管理',
    title: '权限列表'
  },
  beforeEach (to, from, next) {
    check('/role_permission', next)
  }
},
{
  path: 'service_comp',
  component: () => import('@/views/service_comp/service_comp.vue'),
  meta: {
    fa: '劳务管理',
    title: '劳务公司管理'
  },
  beforeEach (to, from, next) {
    check('/service_comp', next)
  }
},
{
  path: 'vservice_allcomp',
  component: () => import('@/views/service_allcomp/service_comp.vue'),
  meta: {
    fa: '劳务管理',
    title: '劳务人员'
  },
  beforeEach (to, from, next) {
    check('/vservice_allcomp', next)
  }
},
{
  path: 'service_per',
  component: () => import('@/views/service_per/service_per.vue'),
  meta: {
    fa: '劳务管理',
    title: '劳务人员列表'
  },
  beforeEach (to, from, next) {
    check('/service_per', next)
  }
},
{
  path: 'service_compList',
  component: () => import('@/views/service_compList/service_comp.vue'),
  meta: {
    fa: '劳务管理',
    title: '劳务公司列表'
  },
  beforeEach (to, from, next) {
    check('/service_compList', next)
  }
},
{
  path: 'ate_records',
  component: () => import('@/views/ate_records/ate_records.vue'),
  meta: {
    fa: '考勤管理',
    title: '考勤记录'
  },
  beforeEach (to, from, next) {
    check('/ate_records', next)
  }
},
{
  path: 'ate_warning',
  component: () => import('@/views/ate_warning/ate_warning.vue'),
  meta: {
    fa: '考勤管理',
    title: '考勤预警'
  },
  beforeEach (to, from, next) {
    check('/ate_warning', next)
  }
},
{
  path: 'ate_rules',
  component: () => import('@/views/ate_rules/ate_rules.vue'),
  meta: {
    fa: '考勤管理',
    title: '考勤规则'
  },
  beforeEach (to, from, next) {
    check('/ate_rules', next)
  }
},
{
  path: 'shift_manag',
  component: () => import('@/views/shift_manag/shift_manag.vue'),
  meta: {
    fa: '考勤管理',
    title: '班次管理'
  },
  beforeEach (to, from, next) {
    check('/shift_manag', next)
  }
},
{
  path: 'ate_statistics',
  component: () => import('@/views/ate_statistics/ate_statistics.vue'),
  meta: {
    fa: '考勤管理',
    title: '考勤统计'
  },
  beforeEach (to, from, next) {
    check('/ate_statistics', next)
  }
},
{
  path: 'ate_historical',
  component: () => import('@/views/ate_historical/ate_historical.vue'),
  meta: {
    fa: '考勤管理',
    title: '历史考勤'
  },
  beforeEach (to, from, next) {
    check('/ate_historical', next)
  }
},
{
  path: 'ate_historical_someone',
  component: () => import('@/views/ate_historical/ate_historical_someone.vue'),
  meta: {
    fa: '考勤管理',
    title: '历史考勤'
  },
  beforeEach (to, from, next) {
    check('/ate_historical_someone', next)
  }
},
{
  path: 'ate_position',
  component: () => import('@/views/ate_position/ate_position.vue'),
  meta: {
    fa: '考勤管理',
    title: '考勤定位'
  },
  beforeEach (to, from, next) {
    check('/ate_position', next)
  }
},
{
  path: 'shift_manag',
  component: () => import('@/views/shift_manag/shift_manag.vue'),
  meta: {
    fa: '考勤管理',
    title: '排班管理'
  },
  beforeEach (to, from, next) {
    check('/shift_manag', next)
  }
},
{
  path: 'ate_attendance',
  component: () => import('@/views/ate_attendance/ate_attendance.vue'),
  meta: {
    fa: '考勤管理',
    title: '未推送实时考勤'
  },
  beforeEach (to, from, next) {
    check('/ate_attendance', next)
  }
},
{
  path: 'ate_history',
  component: () => import('@/views/ate_history/ate_history.vue'),
  meta: {
    fa: '考勤管理',
    title: '未推送历史考勤'
  },
  beforeEach (to, from, next) {
    check('/ate_history', next)
  }
},
{
  path: 'commodity_management',
  component: () => import('@/views/commodity_management/commodity_management.vue'),
  meta: {
    fa: '兑换管理',
    title: '商品管理'
  },
  beforeEach (to, from, next) {
    check('/commodity_management', next)
  }
},
{
  path: 'commodity_classification_list',
  component: () => import('@/views/commodity_management/commodityClassification/list.vue'),
  meta: {
    fa: '商品管理',
    title: '商品分类'
  },
  beforeEach (to, from, next) {
    check('/commodity_classification_list', next)
  }
},
{
  path: 'order_list',
  component: () => import('@/views/order_list/list.vue'),
  meta: {
    fa: '兑换管理',
    title: '兑换记录'
  },
  beforeEach (to, from, next) {
    check('/order_list', next)
  }
},
//设备管理
{
  path: 'specific_management',
  component: () => import('@/views/specific_management/list.vue'),
  meta: {
    fa: '兑换管理',
    title: '商品柜管理'
  },
  beforeEach (to, from, next) {
    check('/specific_management', next)
  }
},
//手机柜管理
{
  path: 'mobileCabinet_management',
  component: () => import('@/views/specific_management/mobileCabinetManagement.vue'),
  meta: {
    fa: '兑换管理',
    title: '手机柜管理'
  },
  beforeEach (to, from, next) {
    check('/mobileCabinet_management', next)
  }
},
//手机柜 人员
{
  path: 'mobileCabinet_managementUser',
  component: () => import('@/views/specific_management/user.vue'),
  meta: {
    fa: '兑换管理',
    title: '手机柜管理'
  },
  beforeEach (to, from, next) {
    check('/mobileCabinet_managementUser', next)
  }
},
//摄像头管理 videoCabinet_management
{
  path: 'videoCabinet_management',
  component: () => import('@/views/specific_management/videoCabinet_management.vue'),
  meta: {
    fa: '兑换管理',
    title: '摄像头管理'
  },
  beforeEach (to, from, next) {
    check('/videoCabinet_management', next)
  }
},
{
  path: 'putGoods',
  component: () => import('@/views/specific_management/putGoods.vue'),
  meta: {
    fa: '兑换管理',
    title: '放入商品'
  },
  beforeEach (to, from, next) {
    check('/specific_management', next)
  }
},
//积分记录
{
  path: 'integral_log',
  component: () => import('@/views/integral_log/list.vue'),
  meta: {
    fa: '积分管理',
    title: '积分记录'
  },
  beforeEach (to, from, next) {
    check('/integral_log', next)
  }
},
//积分统计
{
  path: 'integral_statistics',
  component: () => import('@/views/integral_log/integral_statistics.vue'),
  meta: {
    fa: '积分管理',
    title: '积分统计'
  },
  beforeEach (to, from, next) {
    check('/integral_statistics', next)
  }
},
{
  path: 'test_integral',
  component: () => import('@/views/test_integral/test_integral.vue'),
  meta: {
    fa: '商品管理',
    title: '积分兑换记录'
  },
  beforeEach (to, from, next) {
    check('/test_integral', next)
  }
},
{
  path: 'training_man',
  component: () => import('@/views/training_man/training_man.vue'),
  meta: {
    fa: '培训管理',
    title: '培训列表'
  },
  beforeEach (to, from, next) {
    check('/training_man', next)
  }
},

//
{
  path: 'training_category_list',
  component: () => import('@/views/training_man/training_category_list.vue'),
  meta: {
    fa: '培训管理',
    title: '培训分类'
  },
  beforeEach (to, from, next) {
    check('/training_category_list', next)
  }
},
{
  path: 'video_collection',
  component: () => import('@/views/training_man/video_collection.vue'),
  meta: {
    fa: '培训管理',
    title: '播放配置'
  },
  beforeEach (to, from, next) {
    check('/video_collection', next)
  }
},

{
  path: 'test_bank',
  component: () => import('@/views/test_bank/test_bank.vue'),
  meta: {
    fa: '考试管理',
    title: '题库列表'
  },
  beforeEach (to, from, next) {
    check('/test_bank', next)
  }
},
{
  path: 'examination_paper',
  component: () => import('@/views/examination_paper/examination_paper.vue'),
  meta: {
    fa: '考试管理',
    title: '试卷列表'
  },
  beforeEach (to, from, next) {
    check('/examination_paper', next)
  }
},
{
  path: 'examination_record',
  component: () => import('@/views/examination_record/examination_record.vue'),
  meta: {
    fa: '试卷管理',
    title: '考试记录列表'
  },
  beforeEach (to, from, next) {
    check('/examination_record', next)
  }
},
{
  path: 'set_up',
  component: () => import('@/views/set_up/set_up.vue'),
  meta: {
    fa: '积分管理',
    title: '设置积分'
  },
  beforeEach (to, from, next) {
    check('/set_up', next)
  }
},
{
  path: 'black_list',
  component: () => import('@/views/black_list/black_list.vue'),
  meta: {
    fa: '黑名单管理',
    title: '黑名单列表'
  },
  beforeEach (to, from, next) {
    check('/black_list', next)
  }
},
{
  path: 'white_list',
  component: () => import('@/views/white_list/white_list.vue'),
  meta: {
    fa: '黑名单管理',
    title: '白名单列表'
  },
  beforeEach (to, from, next) {
    check('/white_list', next)
  }
},

]


const routes = [{
  path: '/login',
  component: () => import('@/views/login/login.vue'),
},
{
  path: '/leaderboard',
  component: () => import('@/views/leaderboard/index.vue'),
},
{
  path: '/videolist',
  component: () => import('@/views/videolist/index.vue'),
},
{
  path: '/video_center',
  component: () => import('@/views/training_man/video_center.vue'),
},
{
  path: '/videoDetail',
  component: () => import('@/views/training_man/videoDetail.vue'),
},
{
  path: '/commodityClassificationList',
  component: () => import('@/views/commodity_management/commodityClassification/list.vue'),
},
{
  path: '/',
  component: () => import('@/views/index/index.vue'),
  children: [
    // {
    //   path: 'home',
    //   component: () => import('../views/home/home.vue')
    // },
    {
      path: 'self',
      component: () => import('../views/self/self.vue'),
      meta: {
        title: '个人中心'
      },
    },
    {
      path: 'person_msg',
      component: () => import('@/views/person_msg/person_msg.vue'),
      meta: {
        fa: '集团管理',
        title: '内部人员信息管理'
      },
    },
    ...IndexRoutes,
    // {
    //   path: '',
    //   redirect: 'self'
    // }
  ]
},

{
  path: '*',
  redirect: '/'
}
]

const router = new VueRouter({
  routes
})
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
// // 登录拦截
router.beforeEach((to, from, next) => {
  if (to.path === '/login') {
    next()
    return
  }
  if (to.path === '/video_center') {
    next()
    return
  }
  if (to.path === '/videoDetail') {
    next()
    return
  }
  // 不是去登录，则判断是否已经登陆
  if (store.state.user.token) {
    next()
    return
  }
  next('/login')
})

export default router