import {
  get,
  post,
  deletefn,
  put,
  postExcel,
  getExp,
  post1,
  post2,
} from './index'
//提交

// 项目人员列表的离场人员
export const reqExitList = (page, limit, orgId, perName, perLoginPhone, perIdentificationNumber) => get(`/outside/per/exitList?page=${page}&limit=${limit}&orgId=${orgId}&perName=${perName}&perLoginPhone=${perLoginPhone}&perIdentificationNumber=${perIdentificationNumber}`)


// 
// 加密orgId
export const reqPsdOrgId = (orgId) => get(`/outside/user/userIdEncrypt?orgId=${orgId}`)

/* 登录接口开始 */
export const reqLogin = (params) => post('/inside/login', params, true)
// 修改密码
export const reqUpdPwd = (params) => post('/inside/userupdatepassword', params, true)
/* 结束 */
// 多组织多角色设置是否重要职责
export const reqManyOrgAndRoleByGroup = (org_id, po_id, per_id) => get(`inside/saveImportGroupOrg/${org_id}/${po_id}/${per_id}`)

// 首页
// 获取项目信息
export const reqGetProInfo = (projectId) => get(`/inside/IndexController/IndexProject?projectId=${projectId}`)
// 柱状图
export const reqGetBar = (org_id) => get(`/outside/IndexController/IndexCompanyList?orgIdList=${org_id}`)
// 折线图   
export const reqGetLine = (projectId) => get(`/outside/IndexController/indexLineChart?projectId=${projectId}`)
// 饼图 1
export const reqGetpie1 = (projectId) => get(`/outside/IndexController/ProjectPersonnelData?projectId=${projectId}`)
// 饼图 2
export const reqGetpie2 = (projectId) => get(`/outside/IndexController/PeoplePresent?projectId=${projectId}`)
// 饼图 3
export const reqGetpie3 = (projectId) => get(`/outside/IndexController/AttendanceRecord?projectId=${projectId}`)


/** 集团人员信息接口开始*/
// 人员列表
export const reqUserList = (params) => post('/inside/userlist', params, true)
// 查询一条
// export const reqUserInfo = (params) => post('/inside/userone', params, true)
export const reqUserInfo = (user_id) => get(`/inside/getUser/${user_id}`)
// 添加人员
export const reqUserAdd = (params) => post('/inside/useradd', params, true)
// 添加组织
export const reqAccAdd = (params) => post('/inside/AccountAdd', params, true)
// 修改
export const reqUserUpd = (params) => post('/inside/userupd', params, true)
//查询2号
export const reqUserInfoo = (params) => post('/inside/userone', params, true)
// 删除
export const reqUserDel = (params) => post('/inside/userdelall', params, true)
// 获取人员列表总数
export const reqUserCount = () => get('/inside/usercount')
// 下载模板
export const reqTmp = () => getExp('/api/inside/template')
// 批量导出
export const reqUserExcelOutput = (params) => postExcel('api/inside/userexceloutput', params, true)

/** 结束*/

/** 项目列表接口开始*/
export const reqProOrgList = (params) => post('inside/projectorgaizationlist', params, true)
// 查询全部的项目信息
export const reqAllProOrgList = (orgId) => get(`/inside/projectorgaizationlistall?orgId=${orgId}`)
// 查询集团项目列表
export const reqProjectInfoList = (params) => post('/inside/projectinformationlist', params, true)
// 项目列表快的
export const reqProjectInfoLists = (params) => post('/inside/projectinformationlist1', params, true)
// 查询某个
export const reqProjectinfoOne = (params) => post('/inside/projectinformationone', params, true)
// 添加
export const reqProjectInfoAdd = (params) => post('inside/saveProjectInformation', params, true)
// export const reqProjectInfoAdd = (params) => post('/inside/projectinformationadd', params, true)
// 修改
export const reqProjectInfoUpd = (params) => post('/inside/projectinformationupd', params, true)
// 删除
export const reqProjectInfoDel = (params) => post('/inside/projectinformationdel', params, true)
// 总数
export const reqProjectInfoCount = () => get('/inside/projectinformationcount')
// 添加项目下组织
export const reqProAddOrg = (params) => post('/inside/projectorganizationadd', params, true)
// 修改项目下组织
export const reqProUpdOrg = (params) => put('/inside/projectUpdate', params)
// 删除项目下组织
// export const reqProDelOrg = (id) => deletefn(`/inside/projectRemove/${id}`)
export const reqProDelOrg = (id) => deletefn(`/inside/projectRemove/${id}`)
// 查询项目下的角色
export const reqShowPostByPro = (org_id) => get(`/inside/showPostListByProjectOrg/${org_id}`)
// 添加项目组织下的角色
export const reqOrgAddRole = (params) => post('inside/projectorgaizationaddpost', params, true)
// 删除项目组织下的角色
export const reqDelRole = (org_id, po_id) => get(`/inside/removeRole/${org_id}/${po_id}`)
// export const reqOrgDelRole = (params) => post('inside/projectorgaizationaddpost', params, true)
// 根据项目及对应角色查询劳务人员
export const reqShowUserByProAndRole = (org_id, po_id) => get(`/inside/showPerListByProjectOrgAndRole/${org_id}/${po_id}`)
// 根据项目及对应角色删除人员
export const reqShowUserByProDelRole = (org_id, po_id, per_id) => get(`/inside/removePerToProject/${org_id}/${po_id}/${per_id}`)
// 根据项目删除多个人员的多个组织
export const reqdelUserPro = (params) => post('/inside/removePerListToProject', params, true)
// 根据项目及角色添加新劳务人员
export const reqByProAndRoleAddUser = (params) => post('/outside/per/savePerToProject', params, true)
// 项目及对应角色查询一条劳务人员信息（做修改）
export const reqOutOne = (per_id) => get(`/outside/per/getPer/${per_id}`)
// 根据项目及对应角色修改劳务人员
export const reqPerUpd = (params) => put('/outside/per/update', params, true)
// 根据项目及对应角色修改内部人员
export const reqUpdUser = (params) => post('inside/userupdate', params, true)
// 添加劳务人员时获取当前人员下的项目
export const reqGetPro = (orgId) => get(`/inside/projectorgaizationlistall?orgId=${orgId}`)
// 根据查询条件筛选劳务人员(姓名)
export const reqSeaPro = (orgId, poId, per_name, phone, position, createTimeStart, createTimeEnd) => get(`/inside/showPerListByProjectOrgAndRole/${orgId}/${poId}?per_name=${per_name}&per_login_phone=${phone}&position=${position}&createTimeStart=${createTimeStart}&createTimeEnd=${createTimeEnd}`)
// 根据查询条件筛选劳务人员(身份证号)
export const reqSeaIdPro = (orgId, poId, phone, idNumber, position, createTimeStart, createTimeEnd) => get(`/inside/showPerListByProjectOrgAndRole/${orgId}/${poId}?per_identification_number=${idNumber}&per_login_phone=${phone}&position=${position}&createTimeStart=${createTimeStart}&createTimeEnd=${createTimeEnd}`)
// 解析人员身份证
export const reqIdCard = (params) => post('/outside/per/parsingIDCardNumbers', params, true)

/** 结束*/

/** 结束*/

/** 角色列表接口开始*/
// 查询
export const reqPostlist = (params) => post('/inside/postlist', params, true)
// 添加
export const reqPostAdd = (params) => post('/inside/postadd', params, true)

//添加角色
export const reqRoleOrAdd = (params) => post('inside/savePost', params, true)
// 查询单个
export const reqPostOne = (params) => post('/inside/postone', params, true)
// 修改
export const reqPostUpd = (params) => post('/inside/postupd', params, true)
// 删除
export const reqPostDel = (params) => post('/inside/postdel', params, true)
// 总数
export const reqPostCount = () => get('/inside/postcount')

// 设置角色权限
export const reqPostAddJur = (params) => post('/inside/postaddjur', params, true)
// 获取所有权限列表
export const reqAllJurList = () => get('/inside/jurisdictionlist')
// 获取某个权限列表
export const reqJurList = (params) => post('/inside/postselectjur', params, true)
// 获取按钮权限
export const reqBtnPer = (params) => post('/inside/ButtonPermissions', params, true)
// 添加权限
export const reqJurisdictionAdd = (params) => post('/inside/jurisdictionadd', params)
// 修改权限
export const reqJurisdictionUpd = (params) => post('/inside/jurisdictionupd', params)
// 删除权限
export const reqJurisdictionDel = (params) => post('/inside/jurisdictiondel', params)
/** 结束*/

/* 考勤管理接口开始 */
// 获取考勤列表
export const reqAttenList = (params) => post(`/outside/attendance/recordsList`, params, true)
// 获取预警考勤列表
export const reqateWarn = (params) => post(`/outside/AttendanceWarning/AttendanceWarningPage`, params, true)
//考勤统计分页
export const reqateStatisPage = (params) => post(`/outside/statisticalReports/attendance/manage/stats`, params, true)
//获取班组列表
export const reqOrgList = (params) => post(`/inside/projectorgaizationlistallByProjectId`, params, true)
//工种统计
export const reqTofList = (params) => post(`/outside/getTofList`, params, true)
//考勤统计导出
export const reqateStatisExcel = (params) => post(`/outside/statisticalReports/attendance/manage/export`, params, true)
//历史考勤分页
export const reqateHistoricalPage = (params) => post(`/outside/statisticalReports/historical/attendance/stats`, params, true)
//历史考勤导出
export const reqateHistoricalExcel = (params) => post(`/outside/statisticalReports/historical/attendance/export`, params, true)
//人员考勤统计分页
export const reqateSomeOnePage = (params) => post(`/outside/statisticalReports/personnel/attendance/statistics`, params, true)
//人员考勤统计导出
export const reqateSomeOneExcel = (params) => post(`(/outside/statisticalReports/personnel/attendance/statistics/export`, params, true)
//人员考勤明细
export const reqateSomeOneDetail = (params) => post(`/outside/statisticalReports/personnel/attendance/details`, params, true)
//人员考勤明细导出
export const reqateSomeOneDetailExcel = (params) => post(`/outside/statisticalReports/personnel/attendance/details/expor`, params, true)

/* 考勤管理接口结束 */

/** 劳务人员管理接口开始*/
//劳务人员搜索 per_name = "
export const reqOutSerchName = (page, limit, name, orgId) => get(`/outside/per/list/${page}/${limit}?per_name=${name}&orgId=${orgId}`)
// 劳务人员列表页按公司查询---公司列表
export const reqOutComList = (page, limit, name, orgId) => get(`/outside/company/CompanyList`)
// 查看所有劳务人员列表分页
export const reqOutperList = (page, limit, orgId) => get(`/outside/per/new/list/${page}/${limit}/${orgId}`)
// 添加已有人员的列表
export const reqOuthNameEd = (page, limit, orgId) => get(`/outside/per/new/listAll/${page}/${limit}/?orgId=${orgId}`)
// 添加已有人员搜索
export const reqOutSerchNameEd = (page, limit, name, orgId) => get(`/outside/per/new/listAll/${page}/${limit}?name=${name}&orgId=${orgId}`)
// 根据(人员姓名)筛选人员
export const reqOutperNameList = (page, limit, orgId, per_name, project, org_name, position, phone, companyId) => get(`/outside/per/new/list/${page}/${limit}/${orgId}?per_name=${per_name}&project=${project}&org_name=${org_name}&position=${position}&per_login_phone=${phone}&companyId=${companyId}`)
// 根据班组、工种、手机号、入场时间筛选人员(身份证号)
export const saveExistManyGroupMan = (page, limit, orgId, idNumber, project, org_name, position, phone, companyId) => get(`/outside/per/new/list/${page}/${limit}/${orgId}?per_identification_number=${idNumber}&project=${project}&org_name=${org_name}&position=${position}&per_login_phone=${phone}&companyId=${companyId}`)
// 根据项目筛选人员
export const reqOutperProList = (page, limit, orgId, project) => get(`/outside/per/new/list/${page}/${limit}/${orgId}?project=${project}`)
// 全部
export const reqOutperL = (orgId) => get(`/outside/per/list?orgId=${orgId}`)
// 添加劳务人员
export const reqOutperAdd = (params) => post('/outside/per/save', params, true)
// 查询一条人员信息
// export const reqOutperOne = (per_id) => get(`/outside/per/get/${per_id}`)
export const reqOutperOne = (per_id) => get(`/outside/per/getPersion/${per_id}`)
// 角色列表查看一个人员信息
export const reqOutOrgperOne = (org_id, po_id, per_id) => get(`outside/org/getPerByOrgPo/${org_id}/${po_id}/${per_id}`)
// 修改人员信息
export const reqOutperUpd = (params) => put('/outside/per/update', params)
// 批量删除人员信息
export const reqOutperDel = (params) => post('/outside/per/batch_remove', params, true)
// 给人员设置角色
// export const reqOutperTor = (per_id, po_id, org_id) => get(`/outside/per/setPerToRole/${per_id}/${po_id}/${org_id}`)
// 多组织多角色设置是否重要职责
export const reqManyOrgAndRole = (org_id, po_id, per_id) => get(`inside/saveImportProjectOrg/${org_id}/${po_id}/${per_id}`)
// export const reqManyOrgAndRole = (org_id,po_id,per_id) => get(`inside/saveImportProjectOrg/${org_id}/${po_id}/${per_id}`)
// 添加外部人员
// export const reqOutToPer = (org_id, role_id, per_id) => get(`/outside/org/setRoleToPer/${org_id}/${role_id}/${per_id}/`)
// export const reqAddOldPer = (org_id, po_id, params) => post(`outside/org/saveExistManyPer/${org_id}/${po_id}`, params, true)
// 清空人员角色
export const reqremovePerToCompany = (per_id) => get(`/outside/per/removePerToCompany/${per_id}`)
// 查看角色列表
export const reqRoleList = () => get('/outside/role/list')
// 查看关系网
export const reqOutperRel = (per_id) => get(`/outside/per/showPerRoleOrg/${per_id}`)
// 获取工种列表
export const reqOutTofList = () => post('/outside/tof/dictionary/getJobs')
// 查看所属公司
export const reqOutperComp = (per_id) => get(`/outside/per/showPerCompany/${per_id}`)
// 批量导出excel
export const reqOutperExp = (params) => postExcel('api/outside/per/export', params, true)
// export const reqOutperExp = (params) => postExcel('outside/per/export', params, true)
// 批量导入excel
export const reqOutperImp = () => post('/outside/per/import')
// 导出excel模板
export const reqOutperExpmod = () => postExcel('api/outside/per/download')
// export const reqOutperExpmod = () => postExcel('outside/per/download')
// 劳务人员总数
export const reqOutperCount = () => get('/outside/per/countPer')
// 查询人员组织架构
export const reqshowOrgStructure = () => get(`/outside/per/showOrgStructure/${per_id}`)
// 劳务人员头像上传
export const reqPerImg = (params) => post('/outside/per/importImageFile', params, true)
// 组织角色列表删除人员
export const reqPerDel = (org_id, po_id, per_id) => get(`/outside/per/removePerToCompany/${org_id}/${po_id}/${per_id}`)
// 给人员设置角色
export const reqOutperTor = (per_id, po_id, org_id) => get(`/outside/per/setPerToRole/${per_id}/${po_id}/${org_id}`)
// 回显时删除组织角色
export const reqOutOrgDel = (org_id, po_id, per_id) => get(`/outside/per/removePerOrg/${org_id}/${po_id}/${per_id}`)
/** 劳务人员管理接口结束*/


/** 劳务公司管理接口开始*/
// 劳务公司全部树状图
export const reqRoleAllList = () => get('/outside/org/companyOrgListall')
// 劳务公司列表分页
export const reqOutcompList = (page, limit, orgIdList) => get(`/outside/company/list/${page}/${limit}?orgIdList=${orgIdList}`)
export const reqOutcompL = (params) => post(`outside/company/list/loginUser`, params, true)
export const reqOutcompLss = (orgIdList) => get(`outside/company/list?orgIdList=${orgIdList}`)
// 劳务公司树状图
export const reqRoleLis = (params) => post('/outside/org/companyOrgList', params, true)
// 添加劳务公司
export const reqOutcompAdd = (params) => post('/outside/company/save', params)
// 修改
export const reqOutcompUpd = (params) => put('/outside/company/update', params)
// 删除
export const reqOutcompDel = (org_id, orgIdList) => deletefn(`/outside/company/remove/${org_id}?orgIdList=${orgIdList}`)
// 获取单个劳务公司
export const reqOutcompOneList = (org_id) => get(`/outside/company/get/${org_id}`)
// 获取劳务公司信息
export const reqOutcompOrg = (org_id) => get(`/outside/org/get/${org_id}`)
// 
export const reqOutcompOneLi = (org_id) => get(`/outside/company/getCompanyById/${org_id}`)
// 点击公司名称根据id获取角色列表
export const reqOutRoleListByOrg = (org_id) => get(`/outside/company/showRoleListByOrg/${org_id}`)
// 点击角色查看人员列表
export const reqshowPerListByOrgAndRole = (org_id, po_id, perName) => get(`/outside/company/showPerListByOrgAndRole/${org_id}/${po_id}?perName=${perName}`)
// 点击角色查看人员列表按姓名查询
export const reqshowPerListByOrgAndRoleSeach = (org_id, po_id, perName) => get(`/outside/company/showPerListByOrgAndRole/${org_id}/${po_id}?perName=${perName}`)
// 添加角色
export const reqsetRoleToCompany = (org_id, po_id) => get(`/outside/company/setRoleToCompany/${org_id}/${po_id}`)
// 查询单个角色
export const reqOneRole = (po_id) => get(`/outside/role/get/${po_id}`)
// 删除角色
export const reqRoleListDel = (org_id, po_id) => get(`/outside/role/removeRoleToList/${org_id}/${po_id}`)
// 外部组织列表
export const reqOrgOutList = () => get('/outside/org/nested-list')
// 增加组织
export const reqOrgOutAdd = (params) => post('/outside/org/save', params, true)
// 修改组织
export const reqOrgOutUpd = (params) => put('/outside/org/update', params, true)
// 删除组织
export const reqOrgOutDel = (id) => deletefn(`/outside/org/remove/${id}`)
// 根据信用代码查劳务公司信息
export const reqOrgOutListCode = (code) => get(`/outside/company/get/SocialCreditCode/${code}`)
// 已有人员列表
export const reqPerListed = (page, limit, orgId) => get(`/outside/per/labor/listAll/${page}/${limit}?orgId=${orgId}`)
// 已有人员列表 按姓名搜索
export const reqOutSerchPerNameEd = (page, limit, name, orgId) => get(`/outside/per/labor/listAll/${page}/${limit}?name=${name}&orgId=${orgId}`)

/** 劳务公司管理结束*/



/** 内部组织接口开始*/

//内部组织列表
export const reqRoleLists = (params) => post('/inside/grouporgaizationlist', params, true)
//增加组织
export const reqRoleAdd = (params) => post('/inside/grouporgaizationadd', params, true)
//修改组织
export const reqRoleUpd = (params) => put('/inside/grouporgaizationupd', params)
//删除组织
export const reqRoleDel = (org_id) => post(`/inside/grouporgaizationdel/${org_id}`)
//点击获取内部数据角色数据
export const reqRoleByOrg = (org_id) => get(`inside/showPostListByGroupOrg/${org_id}`)
//内部点击角色获取下面人员信息
export const reqRoleAndRole = (org_id, po_id) => get(`inside/showUserListByGroupOrgAndRole/${org_id}/${po_id}`)
//点击添加新角色
export const reqRoleByAdd = (params) => post('/inside/grouporgaizationaddpost', params, true)
//删除----------角色------------取消关联
export const reqPostRoleDel = (params) => post('/inside/postdelfiliation', params, true)
//最新---❥(^_-)---这个接口是添加已有的员工哦
export const reqAddOldUser = (org_id, po_id) => get(`inside/saveExistManyUser/${org_id}/${po_id}`)
//删除---员工
export const reqDelStaff = (org_id, po_id, user_id) => get(`inside/removeUserToGroup/${org_id}/${po_id}/${user_id}`)
//修改---员工
export const reqUpdStaff = (params) => post('inside/userupdate', params, true)
//内部多组织多角色
export const reqInSideManyOrgAndRole = (org_id, po_id, user_id) => get(`inside/saveImportGroupOrg/${org_id}/${po_id}/${user_id}`)

//xxxxxxxxxxxxxx该员工
// export const reqUseUppd=(params)=>post('inside/userupdate',params,true)
//
// // 查看所有内部人员角色列表
// export const reqRolePage = (page, limit) => get(`/inside/per/list/${page}/${limit}`)

/** 结束*/



//集团权限列表
export const reqPermissions = () => get('/inside/jurisdictionlist')


/** 外部组织接口开始*/
//点击获取数据右面角色数据
export const reqOutRoleByOrg = (org_id) => get(`outside/org/showRoleListByOrg/${org_id}`)
//点击角色获取下面人员信息
export const reqOutRoleAndRole = (org_id, role_id) => get(`outside/org/showPerListByOrgAndRole/${org_id}/${role_id}`)


/** 结束*/

/** 外部角色信息接口开始*/

//角色列表信息
export const reqRoleOutList = () => get('/outside/role/list')

//增加组织
export const reqRoleOutAdd = (params) => post('/outside/role/save', params, true)

/** 设备列表接口开始*/
// 设备列表
// export const reqDevList = () => get('/outside/butt/devices/save/list')
// export const reqDevListss = (org_parent) => get(`/inside/getEquipmentByOrgId/${org_parent}`)
export const reqDevLists = (org_parent) => get(`/inside/getEquipmentByParentId/${org_parent}`)
// 设备分页列表
// export const reqDevPageList = (params) => post('/outside/butt/devices/save/list/startPage', params, true)
export const reqDevPageList = (params) => post('/outside/butt/devices/getDeviceList', params, true)
//修改设备列表
export const reqUpdDevList = (org_id) => get(`/inside/getEquipmentByOrgId/${org_id}`)
// 判断人员是否是集团人员
export const reqJudgeList = (org_id) => get(`outside/butt/devices/${org_id}`)

//导出人员
// export const reqOutperExps = (params) => postExcel(`/outside/attendance/export`, params, true)
export const reqOutperExps = (params) => postExcel(`api/outside/attendance/export`, params, true)

/* 考勤定位--模块*/
//考勤列表
export const reqRegisterList = (ordId) => get(`/inside/positioning/list?ordId=${ordId}`)
//添加考勤
export const reqRegister = (params) => post('/inside/positioning/add', params, true)
//获取单个考勤
export const reqGetRegister = (org_id) => get(`/inside/positioning/one/${org_id}`)
//修改考勤
export const reqUpdRegister = (params) => put('/inside/positioning/update', params)
//删除考勤
export const reqDelRegister = (org_id) => get(`/inside/positioning/delete/${org_id}`)

/* 班次管理--模块*/
//班次列表
export const reqShiftList = (page, limit) => get(`/inside/classes/list/${page}/${limit}`)
//添加班次
export const reqShiftAdd = (params) => post('/inside/classes/add', params, true)
//获取单个班次
export const reqGetShift = (org_id) => get(`/i nside/classes/one/${org_id}`)
//修改班次
export const reqUpdShift = (params) => put('/inside/classes/update', params)
//删除班次
export const reqDelShift = (org_id) => get(`/inside/classes/delete/${org_id}`)
//班次项目
export const reqBaiBaiDe = (org_id) => get(`/inside/classes/by/${org_id}`)



/* 考勤规则--模块*/
//考勤规则列表
export const reqAteList = () => get('/inside/attendancerules/list')
//添加考勤规则
export const reqAteAdd = (params) => post1('/inside/attendancerules/add', params)
//获取单个考勤规则
export const reqGetAte = (org_id) => get(`/inside/attendancerules/one/${org_id}`)
//修改班次
export const reqUpdAte = (params) => put('/inside/attendancerules/update', params)
//删除班次
export const reqDelAte = (org_id) => get(`/inside/attendancerules/delete/${org_id}`)
//修改班次的名字和时间
export const reqUpdNameAndTime = (cid, attid, params) => post(`/inside/classesIdUpdateBanC/${cid}/${attid}`, params, true)

/* 题库管理--模块*/
//题库管理列表
export const reqTextList = () => get('/outside/TClassification/list')
//题库管理单选表格列表
export const reqTextTabList = (page, limit) => get(`/outside/TChoiceQuestion/list/${page}/${limit}`)
//添加题库
export const reqTextAdd = (params) => post1('/outside/TChoiceQuestion/add', params)
//修改题库的内容
export const reqTextUpd = (params) => post1('/outside/TChoiceQuestion/update', params)
//删除内容
export const reqTextDel = (subject_id) => get(`/outside/TChoiceQuestion/delete/${subject_id}`)
//获取单条数据
// export const reqTextGetOne = (tChoiceId) => post1(`/outside/TChoiceQuestion/getOne/${tChoiceId}`)
export const reqTextGetOne = (subject_id) => get(`/outside/TChoiceQuestion/getOne/${subject_id}`)

//判断题列表
export const reqTextPdTabList = (page, limit) => get(`/outside/tchoicetrueorfalse/list/${page}/${limit}`)
//添加判断
export const reqTexPdtAdd = (params) => post1('/outside/tchoicetrueorfalse/addtrueorfalse', params)
//获取单个判断
export const reqTextGetPdTabList = (subject_id) => get(`/outside/tchoicetrueorfalse/onetrueorfalse/${subject_id}`)
//编辑判断
export const reqTextPdUpd = (params) => put('/outside/tchoicetrueorfalse/updatetrueorfalse', params)
//删除判断  
export const reqTextDelPdTab = (subject_id) => get(`/outside/tchoicetrueorfalse/deletetrueorfalse/${subject_id}`)


//多选题列表
export const reqTextDxTabList = (page, limit) => get(`/outside/tchoicemultiple/list/${page}/${limit}`)
//添加判断
export const reqTexDxtAdd = (params) => post1('/outside/tchoicemultiple/addtchoicemultiple', params)
//获取单个判断
export const reqTextGetDxTabList = (subject_id) => get(`/outside/tchoicemultiple/onetchoicemultiple/${subject_id}`)
//编辑判断
export const reqTextDxUpd = (params) => put('/outside/tchoicemultiple/updatetchoicemultiple', params)
//删除判断  
export const reqTextDelDxTab = (subject_id) => deletefn(`/outside/tchoicemultiple/deletetchoicemultiple/${subject_id}`)



//考试记录列表
export const reqExamLogUserList = (page, limit, id) => get(`/app/examLogUser/list/${page}/${limit}/${id}`)
//点击id获取考试人分数
// export const reqExamScoresUser = (id) => get(`/app/examScoresUser/showScore/${id}`)
export const reqExamScoresUser = (page, limit, papId) => get(`/app/examScoresUser/list/${page}/${limit}/${papId}`)




/* 题库管理--模块*/


/* 考勤统计--模块*/
export const reqBeonDuty = (params) => post1('/outside/attendance/statistic', params)
//导出人员
// export const reqBeonDutyExps = (params) => postExcel('/outside/attendance/export-checking-in', params, true)
export const reqBeonDutyExps = (params) => postExcel('api/outside/attendance/export-checking-in', params, true)



/* 试卷管理--模块*/
//树状图列表
export const reqExaminationPaList = () => get('/app/examQuestions/papers/list')
//点击id
export const reqExaminationPaId = (page, limit, id) => get(`/app/examQuestions/papersContent/list/${page}/${limit}/${id}`)
//添加试卷
export const reqExaminationPaAdd = (params) => post1('/app/examQuestions/papers/add', params)
//点击看多少人参加考试
export const reqExaminationPaPerson = (page, limit, id) => get(`/app/examUsers/list/${page}/${limit}/${id}`)

// 添加试卷根据项目获取人员列表
export const reqCompPerList = (params) => post1('/app/book/list/project', params)
// 添加试卷搜索全部人员
export const reqSearchCompPerList = (params) => post1('/app/examUsers/userList', params)
/* 试卷管理--结束*/

//考勤二维码
export const reqerweimaAdd = (params) => post1('/outside/test/test2', params)
//编辑时候添加二维码
export const reqPersonQr = (id) => get(`/outside/per/set/QR/${id}`)


//请求人员总数
export const reqPersonList = () => get('/inside/project/count')


// 黑名单管理-------------------------

// 查找身份证
export const reqBlockGetOne = (idnumber) => get(`/outside/blacklist/one/${idnumber}`)
//黑名单列表
export const reqBlockList = (page, limit) => get(`/outside/blacklist/list/${page}/${limit}`)
//添加黑名单
export const reqAddSmallBlockPerson = (params) => put('/outside/blacklist/update', params, true)
//解除
export const reqRelieveSmallBlockPerson = (params) => put('/outside/blacklist/jiechu', params, true)
//获取一个人的信息
export const reqBlockGetOnes = (idnumber) => get(`outside/blacklist/blackone/${idnumber}`)
//修改
export const reqUpdSmallBlockPerson = (params) => put('/outside/blacklist/yuanyin', params, true)
// 白名单列表
export const reqWhiteList = (params) => post(`/outside/SpecialPerson/page`, params, true)
// 添加白名单
export const reqAddSmallWhitePerson = (params) => post('/outside/SpecialPerson/submit', params, true)
// 白名单编辑回显
export const reqWhiteGetOnes = (idnumber) => get(`/outside/SpecialPerson/info/?id=${idnumber}`)
// 白名单修改
export const reqUpdSmallWhitePerson = (params) => post('/outside/SpecialPerson/update', params, true)
// 白名单解除
export const reqRelieveSmallWhitePerson = (id) => get(`/outside/SpecialPerson/remove/?ids=${id}`)



//提交
// reset resetreset








export const reqAteAttendanceList = (page, limit, num, status) => get(
  `/outside/attendance/un-pushed/list/${page}/${limit}?attendanceType=${num}&status=${status}`)
export const reqAteAttendanceName = (page, limit, num, status, statusError, userName, projectId, startTime, endTime) =>
  get(
    `/outside/attendance/un-pushed/list/${page}/${limit}?attendanceType=${num}&status=${status}&statusError=${statusError}&userName=${userName}&projectId=${projectId}&startTime=${startTime}&endTime=${endTime}`
  )
export const reqAteAttendanceId = (page, limit, num, status, statusError, id, projectId, startTime, endTime) => get(
  `/outside/attendance/un-pushed/list/${page}/${limit}?attendanceType=${num}&status=${status}&statusError=${statusError}&idCard=${id}&projectId=${projectId}&startTime=${startTime}&endTime=${endTime}`
)
// export const reqAteAttendanceTime = (page, limit, num, status, statusError, startTime, endTime) => get(`/outside/attendance/un-pushed/list/${page}/${limit}?attendanceType=${num}&status=${status}&statusError=${statusError}&startTime=${startTime}&endTime=${endTime}`)
export const reqAteAttendanceTime = (page, limit, num, status, statusError, startTime, endTime, projectId) => get(
  `/outside/attendance/un-pushed/list/${page}/${limit}?attendanceType=${num}&status=${status}&statusError=${statusError}&startTime=${startTime}&endTime=${endTime}&projectId=${projectId}`
)

//历史考勤
export const reqAteAttendanceListHis = (page, limit, num, status, statusError, projectId) => get(
  `/outside/attendance/un-pushed/list/${page}/${limit}?attendanceType=${num}&status=${status}&statusError=${statusError}&projectId=${projectId}`
)

// export const reqAteAttendanceList = (page,limit) => get(`/outside/attendance/un-pushed/list/${page}/${limit}`)
//手动推送
export const reqAteAttendanceAdd = (params) => post('/outside/attendance/un-pushed/push', params, true)

/*结束*/


/* 积分兑换记录--模块*/
//列表
export const reqTestIntegralList = () => get('/app/exchangeLog/list')


/* 积分设置--模块*/
//列表
export const reqSetUpList = () => get('/app/pointsSet/list')
//添加
export const reqSetUpadd = (params) => post1('/app/pointsSet/add', params)
//修改
export const reqSetUpUpd = (params) => post1('/app/pointsSet/update', params)
//删除
export const reqSetUpDel = (id) => get(`/app/pointsSet/delete/${id}`)

/* 商品管理--模块*/
export const reqLaborGoodsList = (page, limit) => get(`/app/laborGoods/list/${page}/${limit}`)
//商品添加
export const reqLaborGoodsAdd = (params) => post1('/app/laborGoods/add', params)
//商品获取单个
export const reqLaborGoodsget = (id) => get(`/app/laborGoods/getOne/${id}`)
//修改
export const reqLaborGoodsUpd = (params) => post1('/app/laborGoods/update', params)
//商品删除
export const reqLaborGoodsDel = (id) => get(`/app/laborGoods/delete/${id}`)
//商品状态管理
export const reqLaborGoodsStatus = (goodsId, stateNo) => get(`/app/laborGoods/updateState/${goodsId}/${stateNo}`)
//
// 新增商品
export const laborGoodsSave = (params) => post('/outside/laborGoods/submit', params, true)
//编辑商品 
export const laborGoodsEdit = (params) => post('/outside/laborGoods/update', params, true)
//批量删除商品
export const laborGoodsRemove = (id) => get(`/outside/laborGoods/remove?ids=${id}`)
//批量上架 商品 
export const laborGoodsBatchStorage = (id) => get(`/outside/laborGoods/batchStorage?id=${id}`)
//批量下架商品
export const laborGoodsBatchUnshelve = (id) => get(`/outside/laborGoods/batchUnshelve?id=${id}`)
//商品单个上架/下架 /
export const laborGoodsStorage = (id, state) => get(`/outside/laborGoods/storage?id=${id}&state=${state}`)
/*商品管理结束*/
/*订单列表*/
export const orderPageList = (params) => post('/outside/exchangeLog/page', params, true)
//订单列表删除
export const orderRemove = (id) => get(`/outside/exchangeLog/remove/${id}`)
/*设备模块*/
///设备列表
export const devicePageList = (params) => post('/outside/device/page', params, true)
//手机柜管理 
// 列表
export const devicePhonePageList = (params) => post('/outside/device/phone/page', params, true)
//手机柜新增设备信息
export const devicePhoneSave = (params) => post('/outside/device/phone/submit', params, true)
//手机柜设备详情
export const devicePhoneDetail = (id) => get(`/outside/device/phone/info?id=${id}`)
//手机柜设备修改
export const devicePhoneUpdate = (params) => post('/outside/device/phone/update', params, true)
//手机柜删除
export const devicePhoneRemove = (id) => get(`/outside/device/phone/remove?ids=${id}`)
//手机柜 人员 添加
export const devicePhoneUpdateAddUser = (params) => post('outside/device/phone/add/send-data', params, true)
//手机柜 人员 列表
export const devicePhoneUserList = (params) => get('/outside/device/phone/query/send-data', params, true)
//手机柜 人员列表 删除
export const devicePhoneUserRemove = (params) => post(`/outside/device/phone/remove/send-data`, params, true)
//项目下的人员
// export const devicePhoneReqOutperList = (page, limit, orgId) => get(`/outside/per/new/list/${page}/${limit}/${orgId}`)
export const devicePhoneReqOutperList = (page, limit, orgId, name, phone, number) => get(`/outside/per/new/list/${page}/${limit}/${orgId}?per_name=${name}&per_login_phone=${phone}&per_identification_number=${number}`)
// 
// 摄像头 设备列表
export const deviceVideoPageList = (params) => post('/outside/device/video/page', params, true)
//摄像头详情
export const deviceVideoDetail = (id) => get(`/outside/device/video/info?id=${id}`)
//摄像头新增
export const deviceVideoSave = (params) => post('/outside/device/video/submit', params, true)
//摄像头修改
export const deviceVideoUpdate = (params) => post('/outside/device/video/update', params, true)
//摄像头删除
export const deviceVideoRemove = (id) => get(`/outside/device/video/remove?ids=${id}`)
// 
//新增设备
export const deviceSave = (params) => post('/outside/device/submit', params, true)
//修改设备
export const deviceUpdate = (params) => post('/outside/device/update', params, true)
//设备详情
export const deviceDetail = (id) => get(`/outside/device/info?id=${id}`)
//删除设备
export const deviceRemove = (id) => get(`/outside/device/remove?ids=${id}`)
//大屏视频集合
export const trainingVideos = (params) => post('/outside/training/videos', params, true)
/*设备模块结束*/
/*积分管理*/
//积分列表 
export const userIntegralPageList = (params) => post('/outside/userIntegral/page', params, true)
//积分统计
export const userIntegralStatisticsPageList = (params) => post('/outside/userIntegral/statistics-page', params, true)

// 新的商品管理模块
// export const laborGoodsPageList = (params) => post('', params)
//商品分类列表
export const laborGoodsPageList = (params) => post('/outside/product-category/page', params, true)
//
export const laborGoodsDetail = (id) => get(`/outside/laborGoods/info?id=${id}`)
//商品分类列表 不分页
export const productCategorySelectAll = (projectId) => get(`/outside/product-category/selectAll?projectId=${projectId}`)
//通过分类查询商品列表
export const laborGoodsGoodsList = (categoryId) => get(`/outside/laborGoods/goodsList?categoryId=${categoryId}`)
//放入商品
export const deviceGoodsIn = (data) => post1('/outside/device/goods/in', data, true)
//设备内的商品列表
export const deviceGoodsSelectGoods = (params) => post('/outside/device/goods/selectGoods', params, true)
//移除设备内的商品
export const deviceGoodsDeleted = (id) => get(`/outside/device/goods/deleted?id=${id}`)
//设备内商品详情
export const deviceGoodsInfo = (id) => get(`/outside/device/goods/info?id=${id}`)
//修改设备内商品信息
export const deviceGoodEdit = (data) => post('/outside/device/goods/update', data, true)
//新增商品分类
export const categorySav = (data) => post('/outside/product-category/submit', data, true)
//编辑商品分类
export const categoryEdit = (data) => post('/outside/product-category/update', data, true)
//商品分类详情
export const categoryInfo = (id) => get(`/outside/product-category/info?id=${id}`)
///
//商品分类批量上架
export const categoryBatchStorage = (id) => get(`/outside/product-category/batchStorage?id=${id}`)
//商品分类批量下架
export const categoryBatchUnshelve = (id) => get(`/outside/product-category/batchUnshelve?id=${id}`)
//批量删除商品分类
export const categoryBatchRmove = (id) => get(`/outside/product-category/remove?ids=${id}`)
//商品分类上下架
export const categoryStorage = (id, state) => get(`/outside/product-category/storage?id=${id}&state=${state}`)
//商品列表 
export const CommodityList = (params) => post('/outside/laborGoods/page', params, true)
//项目列表不分页
export const getselectProjectList = (id) => get(`/outside/laborGoods/selectProjectList`)

/* 培训管理--模块*/
//积分排行榜
export const sumSelect = () => get('/outside/sum/select')
//公司排行榜
export const integralSumSelect = () => post('/outside/company-integral-num/select')

// export const  = (params) => post('/outside/laborGoods/page', params, true)
//列表
export const reqTrainingList = (page, limit) => get(`/outside/training/list/${page}/${limit}`)
//添加视频
export const reqATrainingAdd = (params) => post1('/outside/file/importVideo', params, true)

//添加
export const reqATrainingAddAdion = (params) => post1('/outside/training/save', params, true)
//修改
export const reqATrainingUpd = (params) => put('/outside/training/update', params)
//删除
// export const reqATrainingDel = (id) => deletefn(`/outside/training/remove/${id}`)
//培训 视频配置列表
export const trainingCategoryVideoList = (params) => post(
  `/outside/training-category/videos`, params, true
)
//创新中心分类
export const selectPolling = (id) => get(`/outside/training-category/selectPolling?id=${id}`)
//启用 禁用视频配置列表
export const trainingCategroyStart = (id) => get(`/outside/training-category/start?id=${id}`)
export const trainingCategroyStop = (id) => get(`/outside/training-category/stop?id=${id}`)
export const reqATrainingDel = (id) => deletefn(`/outside/training/remove/${id}`)
// 新的培训列表   
export const trainingPageList = (params) => post(
  `/outside/training/page`, params, true
)
//分页 培训分类列表 
export const trainingCategoryPageList = (params) => post(
  `/outside/training-category/page`, params, true
)
//培训分类列表不分页
export const trainingCategoryPageListAll = () => get(
  `/outside/training-category/selectAll`
)
//培训分类删除
export const trainingCategoryRemove = (id, projectId) => get(`/outside/training-category/remove?ids=${id}&projectId=${projectId}`)
//培训分类上下架
export const trainingCategoryStorage = (id, state) => get(`/outside/training-category/storage?id=${id}&state=${state}`)
//培训分类详情
export const trainingCategroyDetail = (id) => get(`/outside/training-category/info?id=${id}`)
//培训分类 新增
export const trainingCategorySave = (params) => post('/outside/training-category/submit', params, true)
//培训分类修改
export const trainingCategoryEdit = (params) => post('/outside/training-category/update', params, true)
//培训列表删除
export const trainingRemove = (id) => get(`/outside/training/remove/${id}`)
// 培训列表详情
export const trainingGetDetail = (id) => get(`/outside/training/get/${id}`)
// 培训列表-校验同一项目下文件名称不可重复
export const trainingCheck = (params) => post('/outside/training/check', params, true)
//预览文件
export const previewFile = (params) => post2(`/outside/file/preview-file-url`, params, true)
//培训新增
export const trainingSubmit = (params) => post2('/outside/training/submit', params, true)
//培训编辑
export const trainingEdit = (params) => post2('/outside/training/update', params, true)
// export const laborGoodsPageList = (params) => post('/outside/product-category/page', params, true)
/* 培训管理--结束 */