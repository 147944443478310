import {
  reqUserCount,
  reqUserList
} from '../../request/api'
import {
  errorAlert
} from '@/utils/alert'
// 状态
const state = {
  // 初始值
  allList: [], // 存放所有信息
  search: [], // 搜索后的数组
  list: [],
  key: '',
  page: 1,
  total: 0,
  limit: 10,
  loading: false,
}
// 修改状态
const mutations = {
  // getAllList(state, arr) {
  //     state.allList = arr
  // },
  changeList(state, arr) {
    state.list = arr
    state.loading = false
  },
  changeTotal(state, num) {
    state.total = num
  },
  changePage(state, num) {
    state.page = num
    state.loading = true
  },
  changeSearch(state, key) {
    state.search = [];
    state.page = 1;
    state.key = key;
    state.loading = true
  },
}
// 异步和逻辑操作
const actions = {
  // 请求列表
  reqList({
    commit,
    dispatch,
    state: {
      page,
      limit,
      // search,
      key
    }
  }) {
    // if (search.length == 0) {
    // 发请求
    reqUserList({
      page,
      limit,
      key
    }).then(res => {
      const list = res.data.Records ? res.data.Records : [];
      list.forEach(i => {
        for (let j in i) {
          if (j == 'id_number') {
            i.idCard = i[j].replace(/(?<=\d{6})\d{9}(?=\d{2})/, '*********')
          }
        }
      })
      if (list.length == 0 && page !== 1) {
        commit('changePage', page - 1)
        dispatch('reqList')
        return
      }
      commit('changeList', list)
      commit('changeTotal', res.data.total)
    }).catch(() => {
      errorAlert("服务器请求错误");
    });
    // } else {
    //     page--;
    //     let a = search.slice(page * 10, (page * 10) + 10);
    //     commit('changeList', a)
    // }
  },
  changePage({
    commit,
    dispatch
  }, num) {
    commit('changePage', num)
    dispatch('reqList')
  },
  // 搜索框
  changeSearch({
    commit,
    dispatch
  }, key) {
    commit('changeSearch', key)
    dispatch('reqList')
  }
}
// getters 导出数据
const getters = {
  // allList(state) {
  //     return state.allList
  // },
  list(state) {
    return state.list
  },
  loading(state) {
    return state.loading
  },
  total(state) {
    return state.total
  },
  limit(state) {
    return state.limit
  },
  page(state) {
    return state.page
  },
  search(state) {
    return state.search
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
  namespaced: true
}