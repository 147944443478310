<template>
  <!-- icon="el-icon-delete" -->
  <el-button type="primary" @click="del()" size="small" class="delBtn">删除</el-button>
</template>
<script>
export default {
  methods: {
    // 删除
    del() {
      this.$confirm("此操作将永久删除该信息, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          // 让父亲删除
          this.$emit("confirm");
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除"
          });
        });
    }
  }
};
</script>
<style scoped lang="less">
// /deep/.delBtn.el-button {
// }
.el-table .cell {
  .el-button--primary {
    // font-size: 14px;
    color: #3051ff;
    outline: none;
    border: none;
    padding: 0;
    background: transparent !important;
  }
  .el-button--primary:focus,
  .el-button--primary:hover {
    text-decoration: underline;
  }
}
</style>